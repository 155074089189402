<template>
  <div class="billing">
    <div class="billing__header">
      <div>
        <div class="billing__label">Fakturamottaker</div>
        <div class="billing__sublabel">Vi henter fakturamottaker fra meglerkontoret</div>
      </div>
      <Button :label="edit ? 'Avbryt' : 'Endre'" @click="toggle" class="billing__edit" v-if="!preview" />
    </div>
    <component
      :is="component"
      :value="value"
      v-bind="$attrs"
      @input="$ev => $emit('input', $ev)"
      class="billing__input"
    />
  </div>
</template>

<script>
import ViewComponent from './View.vue'
import MutateComponent from './Mutate.vue'
import { ButtonComponent as Button } from 'vue-elder-button'
import { Clone } from '@/utils'

export default {
  data() {
    return {
      edit: false,
    }
  },
  props: {
    value: Object,
    template: Object,
    preview: Boolean,
  },
  computed: {
    component() {
      if (this.edit) return MutateComponent
      return ViewComponent
    },
  },
  methods: {
    toggle() {
      let newState = !this.edit

      this.$emit('input', Clone(this.template))

      this.edit = newState
    },
  },
  components: {
    Button,
  },
}
</script>

<style lang="scss">
.billing {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__input {
    margin-top: var(--spacing-default);
    border: 1px solid var(--light-grey);
    border-radius: var(--border-radius);
  }

  &__label {
    @include typography('h3');
    margin-bottom: var(--spacing-sm);
  }
}
</style>
