<template>
  <Card
    title="Innstillinger for prosjekt"
    subtitle="Her får du en oversikt over diverse innstillinger du kan foreta deg"
    variant="prompt"
    class="admin-settings"
  >
    <template #default>
      <template v-if="!payload.hasProject">
        <div class="bold">Ønsker du å bestille/oppgradere til fullverdig prosjektside?</div>
        <Button
          class="mt-2 mb-1"
          label="Ja, oppgrader til fullverdig prosjektside"
          theme="primary"
          icon="fa-pro-solid:arrow-up-right-from-square"
          @click="
            {
              $emit('reset')
              $emit('navigate', 'order')
            }
          "
        />
        <Alert iconPlacement="left" class="mb-4">
          <template>
            <div>Kommer-for-salg siden vil nå erstattes av en fullverdig prosjektside.</div>
          </template>
        </Alert>
      </template>

      <template v-if="payload.flatfinderEditUrl">
        <div class="bold">Tilpass eller opprett boligvelger i Kvass</div>

        <Button
          class="mt-2 mb-4"
          label="Boligvelger i Kvass"
          theme="primary"
          icon="fa-pro-solid:arrow-up-right-from-square"
          tag="a"
          target="_blank"
          :href="payload.flatfinderEditUrl"
          :disabled="payload.project.archived"
        />
      </template>

      <div class="bold">{{ publishLabel }}</div>
      <RadioSegment
        class="mt-2 mb-1 admin-settings__publish"
        :class="{ 'admin-settings__publish--published': hasPublished }"
        :disabled="hasPublished || payload.project.archived || (!payload.hasProject && payload.upcoming.archived)"
        v-model="publish"
        @input="Publish"
        :promise="promise"
        :items="items"
        @onSuccess="() => (hasPublished = true)"
        @onError="() => (publish = false)"
        v-tooltip="{
          content: hasPublished ? 'For å avpublisere prosjektet, ta kontakt med support@kvass.no' : '',
          placement: 'right',
          delay: 0,
          offet: 20,
          classes: ['core-broker-step-products__tooltip'],
        }"
      />

      <Alert iconPlacement="left" class="admin-settings__publish-notification">
        <template>
          <div class="bold">
            Prosjektet blir nå publisert på
            <a target="_blank" :href="payload.homepage.url" class="bold">{{ payload.homepage.label }}</a> nettside.
          </div>
          <div>Publisering påvirker ikke</div>
          <ul class="mt-0">
            <li>
              Finn annonse for prosjektet
            </li>
            <li>
              Annet annonseringsmateriell
            </li>
          </ul>
        </template>
      </Alert>

      <Alert v-if="payload.upcoming.published && payload.hasProject" iconPlacement="left" class="mt-2">
        <template>
          <div>Kommer-for-salg siden er fortsatt publisert frem til fullverdig prosjektside er lansert.</div>
        </template>
      </Alert>

      <template v-if="payload.project.preview || payload.upcoming.preview">
        <Button
          class="mt-4"
          :label="payload.hasProject ? 'Synkroniser prosjektside' : 'Synkroniser kommer for salg side'"
          icon="fa-pro-solid:rotate"
          theme="primary"
          :disabled="payload.hasProject ? payload.project.archived : payload.upcoming.archived"
          @click="Update"
          :promise="updatePromise"
          :labels="{
            success: 'Synkronisert',
            loading: 'Synkroniserer',
            error: 'Noe gikk galt',
          }"
        />

        <Alert iconPlacement="left" class="admin-settings__update-notification mt-2">
          <template>
            <div>
              Fagsystemet synkroniseres én gang i døgnet automatisk, og når fagsystemet sender push varsler mot Kvass.
              Du kan også synkronisere nettsiden via knappen.
            </div>
          </template>
        </Alert>
      </template>
    </template>
    <template #actions v-if="payload.project.preview || payload.upcoming.preview">
      <Button
        :label="payload.hasProject ? 'Forhåndsvis prosjektside' : 'Forhåndsvis kommer for salg side'"
        icon="fa-pro-solid:arrow-up-right-from-square"
        theme="primary"
        tag="a"
        target="_blank"
        :href="payload.project.preview || payload.upcoming.preview"
      />
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card-v2.vue'
import { InputComponent as Input } from 'vue-elder-input'
import { ButtonComponent as Button } from 'vue-elder-button'
import { AlertComponent as Alert } from 'vue-elder-alert'
import RadioSegment from '@/components/RadioSegment.vue'
import fetch from '@/api/fetch'

export default {
  inject: ['$token'],

  props: {
    payload: Object,
    value: Object,
  },
  data() {
    return {
      updatePromise: null,
      publish: false,
      promise: null,
      hasPublished: false,
    }
  },

  computed: {
    token() {
      return this.$token()
    },
    publishLabel() {
      return this.payload.hasProject ? 'Publiser prosjektside' : 'Publiser kommer-for-salg'
    },
    publishDisabled() {
      if (this.payload.project.published) return true
      if (this.payload.hasProject) return false
      return Boolean(this.payload.upcoming.published)
    },

    items() {
      return [
        {
          value: false,
          label: 'Nei',
        },
        {
          value: true,
          label: 'Ja',
        },
      ]
    },
  },

  methods: {
    Publish() {
      const { tenant, integrationId } = this.payload
      const url = `https://${tenant}/api/integration/${integrationId}/callbacks/publish?token=${this.token}`

      this.promise = fetch(url, {
        method: 'POST',
        body: JSON.stringify({ caseId: this.payload.caseId }),
      })
    },

    Update() {
      const {
        tenant,
        project: { integrationId: projectIntegrationId },
        upcoming: { integrationId: upcomingIntegrationId },
      } = this.payload
      const id = projectIntegrationId || upcomingIntegrationId
      const url = `https://${tenant}/api/integration/${id}/actions/syncronize`

      this.updatePromise = fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Kvass ${this.payload.uploadToken}`,
        },
        credentials: 'omit',
      })
    },
  },

  mounted() {
    this.publish = this.publishDisabled
    this.hasPublished = this.publishDisabled
  },

  components: {
    Input,
    Button,
    Card,
    Alert,
    RadioSegment,
  },
}
</script>

<style lang="scss">
.admin-settings {
  &__publish-notification {
    .alert__content {
      align-items: start;
    }
    .alert__icon {
      margin-top: 0.2rem;
    }
  }

  &__update-notification {
    width: 100%;
  }

  .admin-settings__publish {
    &--published {
      .radio-segment__radio--selected {
        color: var(--success-contrast);
        background-color: var(--success);
      }
    }
  }

  .card-v2__actions {
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
